<script>
export default {
	name: "Record",
	computed: {
		userNTInfo(){
			return this.$store.state.userNTInfo
		},
	},
	data(){
		return{
			activeTab: "status",
			detailIndex: null
		}
	},
	mounted(){
		let vm = this;
		if(vm.$route.fullPath.indexOf("friend")!==-1){
			vm.activeTab="friend"
		}
	},
	methods: {
		dateChange(str){
			return str.slice(0, 10) 
		},
		changeTab(target){
			// console.log("changeTab",target)
			let vm = this;
			vm.activeTab = target;
		},
		showInfoDetail(index){
			// console.log("showInfoDetail",index)
			let vm = this;

			if (index == vm.detailIndex) {
				vm.detailIndex = null;
			} else {
				vm.detailIndex = index;
			}
		}
	},
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>